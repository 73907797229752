.media-upload .upload-container {
  padding: 0 16px;
}

.media-upload .uploaded {
  justify-content: space-between;
}

.media-upload .icon {
  font-size: 3rem;
}

.media-upload .text {
  line-height: 1.4;
  text-align: left;
}

.media-upload .remove-file-btn,
.media-upload .remove-file-btn:hover {
  background: transparent;
  color: #00457c;
}

.media-upload .remove-file-btn {
  border: none;
  box-shadow: none;
  padding: 0;
}

.media-upload .thumbnail {
  max-height: 60px;
  max-width: 120px;
}