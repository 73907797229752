.mms-message-counter {
  align-items: center;
  color: #707070;
  display: flex;
  justify-content: flex-end;
}

.mms-message-counter .highlight {
  font-weight: 600;
}

.mms-message-counter .letterCount {
  color: #000;
  font-size: 12px;
}
