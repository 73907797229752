.extension-contact-form-fields .phone-preview-col {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}

.extension-contact-form-fields .sender-message-card-col {
  padding: 24px 0 40px 24px !important;
}

.extension-contact-form-fields .button-bar {
  background: #fff;
  border-top: 1px solid #ccc;
  bottom: 0;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 10px;
  padding-bottom: 5px;
  position: fixed;
  right:0;
  width: 100%;
  z-index: 1;
}

.extension-contact-form-fields .button-bar .button-wrapper {
  position: relative;
  width: 150px;
}

.extension-contact-form-fields .button-bar .button-wrapper.full {
  text-align: right;
  width: 100%;
}

.extension-contact-form-fields .button-bar button {
  background: #0275d8;
  border-color: #0275d8;
  border-radius: 5px;
  bottom: 0;
  color: #fff;
  position: absolute;
  right: 0;
}

.extension-contact-form-fields .button-bar .button-wrapper.full button {
  position: relative;
}

.extension-contact-form-fields .button-bar button:hover {
  background-color: #00457c;
  border-color: #00457c;
}

.extension-contact-form-fields .button-bar button:disabled {
  background-color: #8a8b8d;
  border: 1px solid transparent;
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .extension-contact-form-fields .sender-message-card-col {
    padding: 30px !important;
  }
}

@media only screen and (max-width: 890px) {
  .extension-contact-form-fields .sender-message-card-col {
    padding-top: 13px !important;
  }
  .extension-contact-form-fields .phone-preview-col {
    padding-top: 15px;
  }
}

.button-bar .typeformWrapper {
  padding-left: 38px;
  position: relative;
  width: 100%;
}

.button-bar .typeformWrapper button {
  position: relative;
}

.button-bar .typeformWrapper button.button-icon {
  height: 32px;
  margin-left: 1rem;
  padding: 0;
  width: 32px;
}

.button-bar .typeformWrapper button.button-icon:hover {
  opacity: 0.5;
}

.button-bar .typeformWrapper button.button-icon:active,
.button-bar .typeformWrapper button.button-icon:focus {
  border: 1px dashed #eee !important;
}

.button-icon {
  background-color: transparent !important;
  border: none !important;
  color: black !important;
}

.closeIcon {
  background: transparent;
}