.phone-preview {
  height: 479px;
  margin: 0 auto;
  padding-top: 0;
  pointer-events: none;
  position: fixed;
  width: 272px;
}

.phone-preview .phone-frame {
  position: absolute;
}

.phone-preview .phone-number-container {
  position: absolute;
  top: 29px;
}

.phone-preview .text-center {
  text-align: center;
  width: 100%;
}

.phone-preview .text-center .phone-number {
  font-size: 0.875em;
}

.phone-preview .phone-message-content {
  background: #FFFFFF;
  height: 413px;
  left: 8px;
  margin-top: 10px;
  overflow: auto;
  padding: 20px 10px;
  position: absolute;
  top: 50px;
  width: 256px;
}

.phone-preview .phone-message-content .message-bubble {
  background-color: #F1F1F1;
  border-radius: 10px;
  display: inline-block;
  max-width: 170px;
  overflow: hidden;
  padding: 10px;
  word-wrap: break-word;
}

.phone-preview .mmsImage {
  border-radius: 0.625rem;
  max-width: 170px;
}

@media only screen and (max-width: 890px) {
  .phone-preview .phone-frame {
    height: 100%;
  }

  .phone-preview .phone-number-container {
    top: 25px;
  }

  .phone-preview .phone-message-content {
    height: 375px;
    left: 20px;
    top: 43px;
    width: 232px;
  }
}