.configure-channel-card {
  margin: 1.5rem;
}

.sender-id {
  width: 100%;
}

.button-wrapper {
  margin-top: 1rem;
  text-align: right;
}

.spinner-layout {
  background: #fafafa;
  overflow: hidden;
  overflow-y: auto;
}

.spinner-layout .spin {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}