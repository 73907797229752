.message-box {
  margin-bottom: 20px;
}

.message-box .row {
  background: #f4f4f4;
  border: 1px solid #d7d7d7;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  padding: 10px 0;
}

.message-box textarea {
  border: 1px solid #d9d9d9;
}

.message-box textarea.error {
  color: #ce2626;
}

.message-content-container textarea.ant-input.disabled.ant-input-disabled {
  background-color: #ffffff !important;
}

.message-box .message-content-container {
  border: 0.0625rem solid #d7d7d7;
  border-radius: 0.25rem;
  border-radius: 0;
  overflow: hidden;
  padding: 16px;
  position: relative;
  width: 100%;
}

.message-box .placeholder {
  align-items: center;
  color: #333;
  display: flex;
}

.message-box .placeholder .anticon {
  margin-right: 5px;
}

.message-box .ant-select-show-search {
  width: 100%;
}

.message-box textarea {
  border: 0;
}

.message-box .footer {
  align-items: center;
  background: #f4f4f4;
  border: 1px solid #d7d7d7;
  border-radius: 0 0 5px 5px;
  border-top: 0;
  display: flex;
  font-size: 11.5px;
  min-height: 30px;
  padding: 0 10px;
}

.message-box .templates-field {
  width: 125px;
}

.message-box .ant-form-item {
  margin-bottom: 0;
}

.message-box .ant-tabs {
  color: rgba(0, 0, 0, 0.65);
}

.message-box .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none;
}

.message-box .ant-input:focus {
  box-shadow: none;
}
