.sender-and-recipients .header {
  font-size: .8125em;
  font-weight: 500;
}

.sender-and-recipients .sender-id {
  width: 100%;
}

.sender-and-recipients .tooltip {
  margin-left: 5px
}

.sender-and-recipients .recipients {
  margin-bottom: 5px;
}

.sender-and-recipients .message {
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.ant-select.tfn-error .ant-select-selector {
  border-color: #ce2626 !important;
}

.ant-select.tfn-error.ant-select-focused .ant-select-selector {
  box-shadow: 0 0 0 2px #ce262620 !important;
}

.tfn-error-message {
  background-color: #fefae5;
  border-color: #f9ac14;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 15px;
  padding: 1rem;
}

.tfn-error-message p {
  font-weight: 600;
  margin-bottom: 0;
}

.anticon.anticon-exclamation-circle.tfn-invalid-warning {
  color: #f9ac14;
  margin-top: 0.3rem;
  vertical-align: top;
}

.verify-text {
  color: #1890ff;
  position: absolute;
  right: 1.5rem;
}

.verify-text-link {
  color: #1890ff;
  text-decoration: none;
}