.typeformWidget {
  background-color: white !important;
  border: none;
  color: #1890ff !important;
  cursor: pointer;
  left: 0;
  position: relative;
  right: auto;
  text-decoration: underline;
}

.typeformWidget:hover {
  opacity: 0.5;
}

.typeformWidget.invisible {
  visibility: hidden;
}