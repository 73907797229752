.extension-contact .card {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.09);
  margin-bottom: 2rem;
}

.extension-contact .ant-card-head {
  border-bottom: none;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1.25rem;
  font-weight: 400;
}

.extension-contact .ant-card-body {
  padding: 0px 24px 10px;
}

.extension-contact .spin {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ant-select.personalise-dropdown {
  position: absolute;
  top: 0px;
  width: 150px;
}

.ant-select.personalise-subject.personalise-dropdown {
  right: 0px;
  top: -5px;
}

.ant-select.templates-field.ant-select-disabled,
.ant-select.personalise-dropdown.ant-select-disabled .personalise-placeholder,
.ant-select.personalise-dropdown.ant-select-disabled .personalise-placeholder span.anticon {
  opacity: 50%;
}

.personalise-placeholder {
  color: rgba(0, 0, 0, 0.85);
}

.preview-subject {
  font-weight: 600;
}

.ant-select-dropdown.templates-field-dropdown {
  width: auto !important;
}

.ant-layout.layout {
  background: #fafafa;
  height: 100%;
}
