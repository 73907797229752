@import '~antd/dist/antd.css';

body {
  background-color: #fafafa;
}

#root {
  height: 100%;
}

.content {
  padding: 50px 50px;
}

.site-layout-content {
  background: #fafafa;
  height: calc(100% - 48px);
  overflow: hidden;
  overflow-y: auto;
}

.gray {
  color: rgba(0, 0, 0, 0.65);
}

.no-margin {
  margin: 0;
}

.flexVerticalCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flexHorizontalCenter {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.label {
  color: #707070;
  display: block;
  margin-bottom: 8px;
}
