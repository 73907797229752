.trial-account-banner-wrapper{
  display: inline-block;
  width: 58.33%;
}

.trial-account-banner {
  background-color: #fefae5;
  border-color: #f9ac14;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  gap: 0.5rem;
  margin-left: 1rem;
  padding: 1rem;
}

.anticon.anticon-exclamation-circle.trial-account-icon {
  color: #f9ac14;
  margin-top: 0.3rem;
  vertical-align: top;
}

.trial-account-banner p {
  color: #494949;
  margin-bottom: 0;
}
